import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { Box, Stack, Drawer, useTheme } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// config
import { NAV } from '../../../config-global';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
//
import navConfig from './config-navigation';
import NavDocs from './NavDocs';
import NavAccount from './NavAccount';
import NavToggleButton from './NavToggleButton';
import { useRecoilValue } from 'recoil';
import { userState } from 'src/recoil/atoms/auth';
import { PATH_DASHBOARD } from 'src/routes/paths';

// ----------------------------------------------------------------------

NavVertical.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function NavVertical({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const navigate = useNavigate()
  const theme = useTheme()
  // const user = useRecoilValue(userState)
  const isDesktop = useResponsive('up', 'lg');


  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  // console.log(user?.privileges.includes('uwc'))



  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={1}
        sx={{
          pt: 2,
          px: 2.5,
          flexShrink: 0,
          alignItems: 'center',
          width: '100%'
        }}
      >
        <Box
          component="img"
          onClick={() => navigate(PATH_DASHBOARD.root)}
          sx={{
            px: '5px',
            pb: '5px',
            width: '70px',
            height: '70px',
            cursor: 'pointer'
          }}
          alt="App logo."
          src={'/assets/images/logo.png'}
        />

        <NavAccount />
      </Stack>
      <NavSectionVertical data={navConfig} sx={{display:'flex',flexDirection:'column',flex:1,overflowY:'auto'}}/>
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD },
        backgroundColor: theme.palette.secondary.main
      }}
    >
      {/* <NavToggleButton /> */}

      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              zIndex: 0,
              width: NAV.W_DASHBOARD,
              bgcolor: 'transparent',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD,
              backgroundColor: theme.palette.secondary.main,
            },

          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
