import { useRecoilValue } from 'recoil';
import { userState } from '../recoil/atoms/auth';
import { Navigate, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { LoginPage } from 'src/routes/elements';

// ----------------------------------------------------------------------

export default function AuthGuard({ children }) {
  const user = useRecoilValue(userState);
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!user  || !(user?.role?.includes('admin') || user?.role?.includes('moderator'))) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <LoginPage />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <> {children} </>;
}
