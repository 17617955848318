import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "src/firebase/config";

export const PLAYSTORE_LINK = 'https://play.google.com/store/apps/details?id=com.andymation'
export const APPSTORE_LINK = 'https://apps.apple.com/app/andymation-flip-book/id6451327356'

export const varToRoutes = {
  chat_message: 'messages',
  mail: 'mail'
}

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'vertical',
  themeColorPresets: 'default',
  themeStretch: false,
};

export const formatRoleElipsis = (e) => e.seriesIndex === 3 ? (e.w.config.labels[e.seriesIndex].substr(0, 3) + "\u2026") : e.w.config.labels[e.seriesIndex]

export const keysChange = {
  'new': 'new',
  'accepted': 'accepted',
  'pending': 'pending',
  'underreview': 'underreview',
  'banned': 'banned'
}

export const formatNumber = (number) => {
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1) + 'M';
  } else if (number >= 1000) {
    return (number / 1000).toFixed(1) + 'K';
  } else {
    return number.toString();
  }
}

export const add3Dots = (string, limit) => {
  var dots = '...'
  if (string?.length > limit) {
    string = string?.substring(0, limit) + dots
  }

  return string
}

const CLOUD_URL = `https://us-central1-andymation-mobile.cloudfunctions.net`
// const CLOUD_URL = `http://127.0.0.1:5001/andymation-mobile/us-central1`

export const sendNotification = (receiverToken, title, body, data = {}, senderId, receiverId) => new Promise(async (resolve, reject) => {
  try {
    let payload = JSON.stringify({
      to: receiverToken,
      notification: {
        title: capitalizeString(title),
        body: capitalizeString(body)
      },
      data,
      senderId,
      receiverId
    });

    const url = CLOUD_URL + '/sendNotification'
    const resp = await fetch(url, {
      method: "POST",
      body: payload
    })
    resolve(resp)
  } catch (err) {
    console.log('error sending notification', err)
    reject(err)
  }
})

export const capitalizeString = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const uploadFile = (file, path) =>
  new Promise((resolve, reject) => {
    if (!file) return
    const storageRef = ref(
      storage,
      path
    )
    const uploadTask = uploadBytesResumable(storageRef, file)
    uploadTask.on(
      'state_changed',
      // don't want progress
      () => null,
      (error) => {
        reject(error)
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve(downloadURL)
        })
      }
    )
  })

export const uniqueData = (data, key) => {
  return data.reduce((accumulator, current) => {
    if (!accumulator.find((item) => item?.[key] === current?.[key])) {
      accumulator.push(current);
    }
    return accumulator;
  }, [])
};
export const maskEmail = (email = '') => {
  const atIndex = email?.indexOf('@');
  // const dotIndex = email?.lastIndexOf('.');

  if (atIndex && atIndex !== -1) {
    const visiblePart = email.slice(0, 2);
    // const maskedPart = '*'.repeat(atIndex - 3);
    // const visibleEndPart = email.slice(atIndex, atIndex) + email.slice(atIndex);

    return visiblePart + '***@***.com';
  }

  return email;
}
export function sumArray(arr) {
  return arr.reduce((acc, curr) => acc + curr, 0);
}


export function firebaseDateTimeExtractor(date) {
  const providedTimeInMilliseconds = date?.seconds * 1000 + date?.nanoseconds / 1e6;
  return new Date(providedTimeInMilliseconds)
}

export const handleDeleteCloudinaryMedia = async (filename, folder, resource_type) => {
  new Promise(async (resolve, reject) => {
    const deleteMediaUrl = `${CLOUD_URL}/deleteMediaCloudinary`

    await fetch(deleteMediaUrl, {
      method: "POST",
      body: JSON.stringify({ filename, folder, resource_type })
    }).then(() => {
      resolve(true)
    }).catch(err => {
      reject(err)
    })

  })
}

export const isDateBetween = (startDate, endDate, checkDate) => {
  return startDate <= checkDate && checkDate <= endDate;
}

export const handleDownload = (id, name, url) => {
  return new Promise(async (resolve) => {

    console.log({ id, name, url })
    const resp = await fetch(url)
    const blob = await resp.blob()
    const anchor = document.createElement('a');;
    anchor.href = URL.createObjectURL(blob)
    anchor.download = `${name?.trim()} ${id}.mp4`;
    anchor.style.display = 'none';
    document.body.appendChild(anchor);
    anchor.click();
    anchor.remove();
    resolve()
  })
};