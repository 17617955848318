import { format, getTime, formatDistanceToNow } from 'date-fns';
import { firebaseDateTimeExtractor } from '.';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'MMM dd yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
      addSuffix: true,
    })
    : '';
}

export const dateSorter = (a, b) => {
  const aProvidedTimeInMilliseconds = a.createdAt?.seconds * 1000 + a.createdAt?.nanoseconds / 1e6;
  const bProvidedTimeInMilliseconds = b.createdAt?.seconds * 1000 + b.createdAt?.nanoseconds / 1e6;
  return bProvidedTimeInMilliseconds - aProvidedTimeInMilliseconds;
}

export const dataConversionForGraph = (data, dataKey = 'createdAt') => {
  const formatData = data
    ?.map(v => ({ date: typeof (v?.[dataKey]) === 'string' ? fDate(v?.[dataKey]) : fDate(firebaseDateTimeExtractor(v?.[dataKey])), id: v.id }))
  const dateCounts = formatData.reduce((accumulator, item) => {
    accumulator[item.date] = accumulator[item.date] || 0;
    accumulator[item.date]++;
    return accumulator;
  }, {})
  const resultData = Object.entries(dateCounts).map(([date, count]) => ({
    label: date,
    value: count
  }));
  resultData.sort((a, b) => new Date(a.label) - new Date(b.label))
  return resultData
}