import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

const initialValues = {
    defaultCategory: 'Latest'
};

export const settingsState = atom({
    key: 'settings',
    default: initialValues,
    effects_UNSTABLE: [
        persistAtom,
    ],
})