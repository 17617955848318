function path(root, sublink) {
    return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

export const PATH_AUTH = {
    login: path(ROOTS_AUTH, '/login'),
    signup: path(ROOTS_AUTH, '/signup'),
    forgetPassword: path(ROOTS_AUTH, '/forget-password'),
};

export const PATH_PAGE = {
    maintenance: '/maintenance',
    page404: '/404',
    events:'/events',
    flipbook:'/flipbook-fest',
};

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    user: {
        account: path(ROOTS_DASHBOARD, '/user/settings'),
        profile: path(ROOTS_DASHBOARD, '/user/profile'),
        view: (id) => path(ROOTS_DASHBOARD, '/user/' + id),
    },
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    notFound: path(ROOTS_DASHBOARD, '/404'),
    userList: path(ROOTS_DASHBOARD, '/all-users'),
    pending: path(ROOTS_DASHBOARD, '/pending'),
    flipbooks: {
        root: path(ROOTS_DASHBOARD, '/flipbooks'),
        view: (id) => path(ROOTS_DASHBOARD, '/flipbooks/' + id),
        viewEdit: (id) => path(ROOTS_DASHBOARD, '/flipbooks/edit/' + id)
    },
    flaggedComments:path(ROOTS_DASHBOARD, '/flagged-comments'),
    pushNotification:path(ROOTS_DASHBOARD, '/push-notification'),
    categories:path(ROOTS_DASHBOARD, '/categories'),
    intro:path(ROOTS_DASHBOARD, '/intro-video'),
    inApp:path(ROOTS_DASHBOARD, '/app'),
 
}