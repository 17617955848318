import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
(
    <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
    </Suspense>
);

// Auth
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const SignupPage = Loadable(lazy(() => import('../pages/auth/SignupPage')));

//Dashboard
export const DashboardPage = Loadable(lazy(() => import('../pages/Dashboard')));
export const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
export const UserList = Loadable(lazy(() => import('../pages/UserList')));
export const SettingsPage = Loadable(lazy(() => import('../pages/Settings')));
export const PendingPage = Loadable(lazy(() => import('../pages/Pending')));
export const FlipbooksPage = Loadable(lazy(() => import('../pages/Flipbook/Flipbooks')));
export const UserProfilePage = Loadable(lazy(() => import('../pages/UserProfile')));
export const FlipbooksDetailsPage = Loadable(lazy(() => import('../pages/Flipbook/FlipbookDetails')));
export const FlipbooksEditPage = Loadable(lazy(() => import('../pages/Flipbook/FlipbookEdit')));
export const FlaggedCommentsPage = Loadable(lazy(() => import('../pages/FlaggedComments')));
export const PushNotificationPage = Loadable(lazy(() => import('../pages/PushNotification')));
export const CategoriesPage = Loadable(lazy(() => import('../pages/Categories')));
export const IntroVideoPage = Loadable(lazy(() => import('../pages/IntroVideo')));
export const AppPage = Loadable(lazy(() => import('../pages/InApp')));

// Extra
export const EventsInfoPage = Loadable(lazy(() => import('../pages/EventsInfo')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));