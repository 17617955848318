// @mui
import { Stack, Box, useTheme } from '@mui/material';
// config
import { NAV } from '../../../config-global';
// utils
import { hideScrollbarX } from '../../../utils/cssStyles';
// components
import Logo from '../../../components/logo';
import { NavSectionMini } from '../../../components/nav-section';
//
import navConfig from './config-navigation';
import NavToggleButton from './NavToggleButton';

// ----------------------------------------------------------------------

export default function NavMini() {
  const theme = useTheme()
  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_DASHBOARD_MINI - 12,
        }}
      />

      <Stack
        sx={{
          backgroundColor: theme.palette.secondary.main,
          pb: 2,
          pt: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_DASHBOARD_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScrollbarX,
        }}
      >
        {/* <Logo sx={{ mx: 'auto', my: 2 }} /> */}
        <Box
          component="img"

          sx={{
            px: '10px',
            pb: '5px',
            width: '2.8rem',
            m: '0 auto'
          }}
          alt="Your logo."
          src={'https://res.cloudinary.com/codeshock/image/upload/v1687718563/Star_only_white_snos4c.png'}
        />

        <NavSectionMini data={navConfig} />
      </Stack>
    </Box>
  );
}
