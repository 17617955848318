// routes
import { PATH_DASHBOARD } from '../../../routes/paths'
// components
// import Label from '../../../components/label'
// import Iconify from '../../../components/iconify'
import SvgColor from '../../../components/svg-color'

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
)

const ICONS = {
  dashboard: icon('ic_dashboard'),
  analytics: icon('ic_analytics'),
  users: icon('ic_user'),
  blank: icon('ic_blank'),
  flipbooks: icon('ic_folder'),
  flaggedComments: icon('ic_chat'),
  categories: icon('ic_design'),
  notification: icon('ic_bell')
}

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'dashboard', path: PATH_DASHBOARD.root, icon: ICONS.dashboard },
      {
        title: 'Flipbooks', path: PATH_DASHBOARD.flipbooks.root,
        children: [
          { title: 'Pending', path: PATH_DASHBOARD.pending, icon: ICONS.blank, },
          { title: 'Accepted', path: PATH_DASHBOARD.flipbooks.root, icon: ICONS.flipbooks },
        ]
      },
      { title: 'Users', path: PATH_DASHBOARD.userList, icon: ICONS.users },
      { title: 'Flagged Comments', path: PATH_DASHBOARD.flaggedComments, icon: ICONS.flaggedComments },
    ],
  },
  {
    subheader: 'App Admin',
    items: [
      { title: 'Categories', path: PATH_DASHBOARD.categories, icon: ICONS.categories },
      { title: 'Intro videos', path: PATH_DASHBOARD.intro, icon: ICONS.blank },
      { title: 'Push notifications', path: PATH_DASHBOARD.pushNotification, icon: ICONS.notification },
      { title: 'App', path: PATH_DASHBOARD.inApp, icon: ICONS.blank },
    ],
  }
]

export default navConfig
