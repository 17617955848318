import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';
// auth
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import { CustomAvatar } from '../../../components/custom-avatar';
import { userState } from 'src/recoil/atoms/auth';
import { useRecoilValue } from 'recoil';
import { flipbooksState } from 'src/recoil/atoms/flipbooks';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 1.5),
  // borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  const user = useRecoilValue(userState)
  const flipbooks = useRecoilValue(flipbooksState)
  return (
    <Link 
    // component={RouterLink} to={PATH_DASHBOARD.user.account} 
    underline="none" color="inherit" width={'100%'}>
      <StyledRoot>
        <CustomAvatar
          color={user?.profileColor}
          src={user?.imageUrl}
          alt={user?.username || user?.firstName + ' ' + user?.lastName}
          name={user?.username || user?.firstName + ' ' + user?.lastName}
        />

        <Box sx={{ ml: 2, minWidth: 0 }}>
          <Typography sx={{ color: '#caf0f8' }} variant="h6" noWrap>
            {user?.username || user?.firstName + ' ' + user?.lastName}
          </Typography>

          <Typography
            variant="body1"
            noWrap
            sx={{ color: 'text.disabled', fontWeight: 'semibold',textTransform:'capitalize' }}
          >
            {user?.role?.length ? user?.role?.join(', ') : flipbooks?.map(v => v?.authorId)?.includes(user?.uid) ? ["creator"] : ["fan"]}
          </Typography>
        </Box>
      </StyledRoot>
    </Link>
  );
}
