import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

const initialValues = {
    users: []
}

export const usersState = atom({
    key: 'users',
    default: initialValues.users,
    effects_UNSTABLE: [
        persistAtom,
    ],

})
