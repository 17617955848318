import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import { Stack, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// config
import { HEADER, NAV } from '../../../config-global';
// components
import Logo from '../../../components/logo';
import Iconify from '../../../components/iconify';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import ContactsPopover from './ContactsPopover';
import NotificationsPopover from './NotificationsPopover';
import { useEffect, useState } from 'react';
import { db } from 'src/firebase/config';
import { collection, limit, onSnapshot, query, where } from 'firebase/firestore';
import { useRecoilValue } from 'recoil';
import { userState } from 'src/recoil/atoms/auth';
import { defaultSettings } from 'src/utils';

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const theme = useTheme();
  const user = useRecoilValue(userState)
  const [receivedNoti, setReceivedNoti] = useState([]);
  const { themeLayout } = defaultSettings;

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const isDesktop = useResponsive('up', 'lg');

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  useEffect(() => {
    if (db && user) {
      const msgRef = collection(db, 'notifications')
      const receiverQ = query(
        msgRef,
        where('receiver_id', 'in', [user?.uid, user?.email]),
        // orderBy('createdAt', 'desc')
        limit(100)
      )

      const unsubscribe = onSnapshot(receiverQ, (snapshot) => {
        let results = []
        snapshot.docs.forEach((doc) => {
          results.push({
            ...doc.data(), id: doc.id,
          })
        })

        setReceivedNoti(
          results.sort((a, b) => {
            const aDate = new Date(a?.createdAt?.toDate())
            const bDate = new Date(b?.createdAt?.toDate())

            return bDate.getTime() - aDate.getTime()
          })
        )
      })
      return unsubscribe
    }
  }, [user])

  const renderContent = (
    <>
      {isDesktop && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}

      {(!isDesktop || isNavMini) && <Stack direction="row" sx={{ borderRadius: '4px' }}>
        <Typography sx={{ pr: 1, lineHeight: '1rem' }} color='black'>Welcome, {user?.username || user?.firstName + ' ' + user?.lastName} </Typography>
      </Stack>}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        {/* <Searchbar /> */}

        <NotificationsPopover receivedNoti={receivedNoti} />

        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
