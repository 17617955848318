import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

const initialValues = {
    categories: []
}

export const categoriesState = atom({
    key: 'categories',
    default: initialValues.categories,
    effects_UNSTABLE: [
        persistAtom,
    ],

})
