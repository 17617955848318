
import { BrowserRouter } from 'react-router-dom';
import ThemeProvider from './theme'
import { RecoilRoot } from 'recoil';
import SnackbarProvider from './components/snackbar';
import Router from './routes';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';

const App = _ => {
  return (
    <HelmetProvider>
      <RecoilRoot>
        <BrowserRouter>
          <ThemeProvider>
            <SnackbarProvider>
              <Toaster
                position="top-center"
                reverseOrder={false}
              />
              <Router />
            </SnackbarProvider>
          </ThemeProvider>
        </BrowserRouter>
      </RecoilRoot>
    </HelmetProvider>
  );
}

export default App;
