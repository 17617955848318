import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Tooltip, Link, ListItemText } from '@mui/material';

// auth
//
import Iconify from '../../iconify';
import { StyledItem, StyledIcon } from './styles';
import { useState } from 'react';

// ----------------------------------------------------------------------

const NavItem = forwardRef(({ item, depth, open, active, isExternalLink, ...other }, ref) => {
  const [receivedNoti, setReceivedNoti] = useState([]);
  const { title, path, icon, children, disabled, caption, } = item;

  // useEffect(() => {
  //   if (db && user && title === 'messages') {
  //     const msgRef = collection(db, 'notifications');
  //     const receiverQ = query(
  //       msgRef,
  //       where('receiver_id', 'in', [user?.uid || user?.id, user?.email]),
  //       where('type', '==', 'chat_message'),
  //       where('isUnRead', '==', true)
  //     );

  //     const unsubscribe = onSnapshot(receiverQ, (snapshot) => {
  //       let results = [];
  //       snapshot?.docs?.forEach((doc) => {
  //         results?.push({
  //           ...doc?.data(),
  //           id: doc?.id,
  //         });
  //       });

  //       setReceivedNoti(results);
  //     });
  //     return unsubscribe;
  //   }
  // }, [user]);

  // useEffect(() => {
  //   console.log(receivedNoti);
  // }, [receivedNoti]);

  const subItem = depth !== 1;

  const renderContent = (
    <StyledItem ref={ref} open={open} depth={depth} active={active} disabled={disabled} {...other}>
      {icon &&
        (title === 'messages' && receivedNoti.length > 0 ? (
          <StyledIcon sx={{ color: '#a7dc71' }}>{icon}</StyledIcon>
        ) : (
          <StyledIcon>{icon}</StyledIcon>
        ))}

      <ListItemText
        primary={title}
        primaryTypographyProps={{
          noWrap: true,
          sx: {
            width: 72,
            fontSize: 14,
            lineHeight: '16px',
            textAlign: 'center',
            whiteSpace: 'unset',
            wordWrap: 'break-word',

            ...(active && {
              fontWeight: 'fontWeightMedium',
            }),
            ...(subItem && {
              color: 'black',
              fontSize: 14,
              width: 'auto',
              textAlign: 'left',
            }),
          },
        }}
      />

      {caption && (
        <Tooltip title={caption} arrow placement="right">
          <Iconify
            icon="eva:info-outline"
            width={16}
            sx={{
              top: 11,
              left: 6,
              position: 'absolute',
            }}
          />
        </Tooltip>
      )}

      {!!children && (
        <Iconify
          width={16}
          icon="eva:chevron-right-fill"
          sx={{
            top: 11,
            right: 6,
            position: 'absolute',
          }}
        />
      )}
    </StyledItem>
  );

  const renderItem = () => {
    // ExternalLink
    if (isExternalLink)
      return (
        <Link href={path} target="_blank" rel="noopener" underline="none">
          {renderContent}
        </Link>
      );

    // Default
    return (
      <Link component={RouterLink} to={path} underline="none">
        {renderContent}
      </Link>
    );
  };

  return renderItem();
});

NavItem.propTypes = {
  open: PropTypes.bool,
  active: PropTypes.bool,
  item: PropTypes.object,
  depth: PropTypes.number,
  isExternalLink: PropTypes.bool,
};

export default NavItem;
