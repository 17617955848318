import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

const initialValues = {
    flipbooks: []
}

export const flipbooksState = atom({
    key: 'flipbooks',
    default: initialValues.flipbooks,
    effects_UNSTABLE: [
        persistAtom,
    ],

})
