import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  const logo = (
    <Box
      component="img"
      src="/assets/images/logo.png"
      sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    />
  );


  if (disabledLink) {
    return logo;
  }

  return (
    <Box sx={{ width: 80 }}
    >
      <Link component={RouterLink} to="/" sx={{
        display: 'contents',
      }}
      >
        {/* {logo} */}
        <Box
          component="img"
          sx={{
            width: '50px',
            height: '50px',
            margin:'auto'
          }}
          alt="website logo"
          src="/assets/images/logo.png"
        />
      </Link>
    </Box>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
