import { useCallback, useEffect, useState } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { LoginPage, MaintenancePage, Page404, DashboardPage, LandingPage, UserList, SettingsPage, PendingPage, FlipbooksPage, FlipbooksDetailsPage, UserProfilePage, FlaggedCommentsPage, PushNotificationPage, IntroVideoPage, CategoriesPage, AppPage, EventsInfoPage, FlipbooksEditPage } from "./elements";
import GuestGuard from "../auth/GuestGuard";
import AuthGuard from "../auth/AuthGuard";
import CompactLayout from '../layouts/compact';
import { PATH_DASHBOARD, PATH_PAGE } from "./paths";
import DashboardLayout from '../layouts/dashboard';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { auth, db } from 'src/firebase/config'
import { collection, getDocs, onSnapshot, orderBy, query, where } from 'firebase/firestore'
import { userState } from "src/recoil/atoms/auth";
import { usersState } from "src/recoil/atoms/users";
import { flipbooksState } from "src/recoil/atoms/flipbooks";
import { onAuthStateChanged } from "firebase/auth";
import { categoriesState } from "src/recoil/atoms/categories";
import { settingsState } from "src/recoil/atoms/appSetting";

export default function Router() {
    const user = useRecoilValue(userState)
    const resetUser = useResetRecoilState(userState)
    const setUsers = useSetRecoilState(usersState)
    const setFlipbooks = useSetRecoilState(flipbooksState)
    const resetFlipbooks = useResetRecoilState(flipbooksState)
    const setGenres = useSetRecoilState(categoriesState)
    const setAppSetting = useSetRecoilState(settingsState)
    const resetGenres = useResetRecoilState(categoriesState)

    const initialize = useCallback(() => {
        try {
            onAuthStateChanged(auth, async (user) => {
                if (!user) {
                    auth?.signOut()
                    resetUser()
                    resetFlipbooks()
                    resetGenres()
                }
            })
        } catch (error) {
            console.error(error);
        }
    }, []);

    useEffect(() => {
        initialize();
    }, [initialize]);

    useEffect(() => {

        const getUsers = async () => {
            const usersRef = collection(db, 'Users')
            const usersSnap = await getDocs(usersRef)
            let users = usersSnap.docs.map(doc => ({ ...doc.data(), id: doc.id }))
            setUsers(users)
        }
        const getFlipbooks = async () => {
            const flipbooksRef = collection(db, 'Flipbooks')
            const flipbooksSnap = await getDocs(flipbooksRef)
            let flipbooks = flipbooksSnap.docs.map(doc => ({ ...doc.data(), id: doc.id }))
            setFlipbooks(flipbooks)
        }

        if (db && user) {
            // If you want to reset any recoil state just add / uncomment following:
            // setUsers([]), setFlipbooks([])
            // dont forget to comment again after use Hussain
            const usersRef = collection(db, 'Users')
            const flipbooksRef = collection(db, 'Flipbooks')
            const genresRef = collection(db, 'Genres')
            const appSettingRef = collection(db, 'AppSetting')
            const usersQ = query(
                usersRef,
                where('uid', '!=', user?.uid)
            )
            const flipbookQ = query(
                flipbooksRef,
                orderBy('createdAt', 'desc')
            )

            const unsubscribeUsers = onSnapshot(usersQ, (snapshot) => {
                let arr = [];
                let flag = false;

                snapshot.docChanges().forEach((change, ind, items) => {
                    const { type, doc } = change;

                    if (type === "added") {
                        if (items?.length > 4) {
                            flag = true;
                            arr.push({ ...doc.data(), id: doc.id })
                        }
                        else {
                            setUsers(prev => ([...prev, { ...doc.data(), id: doc.id }]))
                        }
                        // setUsers((prevUsers) => {
                        //     let cloned = [...prevUsers, { ...doc.data(), id: doc.id }].filter((value, index, self) =>
                        //         index === self.findIndex((t) => (
                        //             t.id === value.id
                        //         ))
                        //     )
                        //     arr.push(...cloned)
                        //     return prevUsers
                        // })
                    }

                    if (type === "modified") {
                        setUsers((prevUsers) =>
                            prevUsers.map((user) =>
                                user.id === doc.id
                                    ? { ...user, ...doc.data() }
                                    : user
                            )
                        );
                    }

                    if (type === "removed") {
                        setUsers((prevUsers) =>
                            prevUsers.filter((user) => user.id !== doc.id)
                        );
                    }

                });
                if (!!arr.length && flag) {
                    getUsers()
                }

            })

            const unsubscribeFlipbooks = onSnapshot(flipbookQ, (snapshot) => {
                let arr = [];
                let flag = false
                snapshot.docChanges().forEach((change, ind, items) => {
                    const { type, doc } = change;

                    if (type === "added") {
                        // setFlipbooks(prevFlipbooks => {
                        //     const cloned = [...prevFlipbooks, { ...doc.data(), id: doc.id }].filter((value, index, self) =>
                        //         index === self.findIndex((t) => (
                        //             t.id === value.id
                        //         ))
                        //     )
                        //     arr.push(...cloned)
                        // return prevFlipbooks
                        // })
                        if (items?.length > 4) {
                            flag = true;
                            arr.push({ ...doc.data(), id: doc.id })
                        }
                        else {
                            setFlipbooks(prev => ([...prev, { ...doc.data(), id: doc.id }]))
                        }
                    }

                    if (type === "modified") {
                        setFlipbooks((prevFlipbooks) => prevFlipbooks.map((flipbook) =>
                            flipbook.id === doc.id
                                ? { ...flipbook, ...doc.data() }
                                : flipbook
                        ));
                    }

                    if (type === "removed") {
                        setFlipbooks((prevFlipbooks) => prevFlipbooks.filter((flipbook) => flipbook.id !== doc.id));
                    }
                });

                if (!!arr.length && flag) {
                    getFlipbooks()
                }
            })

            const unsubscribeGenres = onSnapshot(genresRef, (snapshot) => {
                // let arr = [];
                snapshot.docChanges().forEach((change) => {
                    const { type, doc } = change;

                    if (type === "added") {
                        setGenres(prev => ([{
                            ...doc.data(),
                            id: doc.id,
                        }, ...prev].filter((value, index, self) =>
                            index === self.findIndex((t) => (
                                t.id === value.id
                            ))
                        )))
                    }

                    if (type === "modified") {
                        setGenres((prev) =>
                            prev.map((item) =>
                                item.id === doc.id
                                    ? {
                                        ...doc.data(),
                                        id: doc.id,
                                    }
                                    : item
                            )
                        );
                    }

                    if (type === "removed") {
                        setGenres(prev => prev.filter(v => v.id !== doc.id))
                    }
                });
            })
            const unsubscribeAppSetting = onSnapshot(appSettingRef, (snapshot) => {
                snapshot.docs.forEach((snap) => {
                    setAppSetting(snap.data())
                });
            })

            return () => {
                unsubscribeUsers()
                unsubscribeFlipbooks()
                unsubscribeGenres()
                unsubscribeAppSetting()
            }
        }
    }, [user])




    return useRoutes([
        {
            path: '/',
            element: <LandingPage />
        },
        // Auth
        {
            path: 'auth',
            children: [
                {
                    path: 'login',
                    element: (
                        <GuestGuard>
                            <LoginPage />
                        </GuestGuard>
                    ),
                },
            ]
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: '',
                    element: (
                        <AuthGuard>
                            <DashboardLayout />
                        </AuthGuard>
                    ),
                    children: [
                        { path: '', element: <DashboardPage /> },
                        { path: 'all-users', element: <UserList /> },
                        { path: 'pending', element: <PendingPage /> },
                        {
                            path: 'flipbooks',
                            children: [
                                { path: '', element: <FlipbooksPage /> },
                                { path: ':id', element: <FlipbooksDetailsPage /> },
                                { path: 'edit/:id', element: <FlipbooksEditPage /> },
                            ]
                        },
                        {
                            path: 'user', children: [
                                { path: 'settings', element: <SettingsPage /> },
                                { path: ':id', element: <UserProfilePage /> }
                            ]
                        },
                        {
                            path: 'flagged-comments', element: <FlaggedCommentsPage />
                        },
                        {
                            path: 'push-notification', element: <PushNotificationPage />
                        },
                        {
                            path: 'categories', element: <CategoriesPage />
                        },
                        {
                            path: 'intro-video', element: <IntroVideoPage />
                        },
                        {
                            path: 'app', element: <AppPage />
                        },
                        { path: '404', element: <Page404 /> },
                    ]
                }
            ]
        },
        { path: 'events', element: <EventsInfoPage /> },
        { path: 'flipbook-fest', element: <EventsInfoPage /> },
        {
            element: <CompactLayout />,
            children: [
                { path: 'maintenance', element: <MaintenancePage /> },
                { path: '404', element: user ? <Navigate to={PATH_DASHBOARD.notFound} /> : <Page404 /> },
            ],
        },
        // { path: 'safe-browsing', element: <Navigate to="/safe-browsinge" replace /> },
        { path: '*', element: <Navigate to={'/'} replace /> },
    ])
}